import { Typography, TextField, Grid, Button } from "@mui/material";
import { Formik, useFormik } from "formik";
import * as yup from 'yup';
import { Box } from "@mui/material";
import axios from "axios";

const validationSchema = yup.object({
    subject:yup
    .string()
    .required()
    .trim(),
    message:yup
    .string()
    .required()
    .trim()
})

const Message=()=>{

    const initialValues = {
        message:'',
        subject: ''
    }

    const onSubmit = (values) =>{
         console.log('From message onSubmit',values)
        axios.post('http://15.207.106.5:9090/api/aws/sns/publishMessage',values)
        .then((response)=>{
            console.log('from message api',response)
        }).catch((error)=>{
            console.log("internal error",error)
        })

    }

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit
    })
    //testing 

return(
    <Box>
    <form onSubmit={formik.handleSubmit}>
    <Grid container spacing={4}>
          <Grid item xs={6} sm={6}>
                        <Typography>
                            Subject
                        </Typography>
                        <TextField
                            label="Subject *"
                            variant="outlined"
                            name={'subject'}
                            fullWidth
                            value={formik.values.subject}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.subject && Boolean(formik.errors.subject)}
                            helperText={formik.touched.subject && formik.errors.subject}

                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography>
                            Message
                        </Typography>
                        <TextField
                            label="Message *"
                            variant="outlined"
                            name={'message'}
                            fullWidth
                            value={formik.values.message}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.message && Boolean(formik.errors.message)}
                            helperText={formik.touched.message && formik.errors.message}

                        />
                    </Grid>
     </Grid>
     <Grid>
     <Button variant={'contained'} type={'submit'} > Add</Button>
     </Grid>
     </form>
    </Box>
)

}
export default Message
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import axios from 'axios';
import { post } from '../../api/rest';



const validationSchema = yup.object({
    strength: yup
        .number()
        .required(),
    items: yup
        .string()
        .required(),
    price: yup
        .string()
        .required()


})
const Catering = () => {

    const initialValues = {
        strength: '',
        items: '',
        price: ''
    }
    const onSubmit = (values) => {
        console.log("from admin catering", values)
        // axios.post('http://15.207.106.5:9090/api/catering/info',values).then((response)=>{
        //     console.log("from post call",response)

        // })

        post("api/catering/info", values);
    }
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    })

    return (
        <>
            <h1>Catering Capacity</h1>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={6} sm={6}>
                        <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                            strength
                        </Typography>
                        <TextField
                            label="Strength *"
                            variant="outlined"
                            name={'strength'}
                            fullWidth
                            value={formik.values.strength}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.strength && Boolean(formik.errors.strength)}
                            helperText={formik.touched.strength && formik.errors.strength}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                            Items
                        </Typography>
                        <TextField
                            label="Items *"
                            variant="outlined"
                            name={'items'}
                            fullWidth
                            value={formik.values.items}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.items && Boolean(formik.errors.items)}
                            helperText={formik.touched.items && formik.errors.items}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                            Price
                        </Typography>
                        <TextField
                            label="Price *"
                            variant="outlined"
                            name={'price'}
                            fullWidth
                            value={formik.values.price}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.price && Boolean(formik.errors.price)}
                            helperText={formik.touched.price && formik.errors.price}
                        />
                    </Grid>
                </Grid>
                <Button type={'submit'} size={'large'} variant={'contained'}>Add</Button>

            </form>

        </>
    )
}
export default Catering
import { Typography, TextField, Grid } from "@mui/material"
import { Form, useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import axios from "axios";

const validationSchema = yup.object({
name: yup
.string()
.required()
.trim()
.min(4, 'Please enter more than two letters')
.max(50, 'Should not exceed 50 letters'),
email: yup
.string()
.email('email required')
.required()
.trim(),
phone: yup
.number()
.required()
.min(4, 'Please enter more than 10 numbers'),
note:yup
.string()
.required()
.trim()
.min(10, 'Please enter more than ten letters')
.max(100, 'Should not exceed 100 letters'),
})
 
 const Contact = () =>{

    const initialValues = {
        name:"",
        email:"",
        phone:"",
        note:""
    }
    const onSubmit = (values)=>{
        console.log(values)
        axios.post('http://15.207.106.5:9090/api/contact/add',values)
        .then((response)=>{
               console.log("from contact get api",response.data)
               console.log("from status response",response.status)
               console.log("only response",response)
               if(response.status == 200){
                alert(response.data);
                formik.resetForm()
              }else if(response.status == 400){
                alert('Bad request: some data is missed')
              }else{
                alert('internal server error')
              }
        })
    }
    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit
    })
    
    return(
        <Box>
            <h1>Contact Us</h1>
        <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4} 
        flexDirection={"column"} 
        >
        <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your name
            </Typography>
            <TextField
              label="Name *"
              variant="outlined"
              name={'name'}
              fullWidth
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your Email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your Number
            </Typography>
            <TextField
              label="Number *"
              variant="outlined"
              name={'phone'}
              fullWidth
              value={formik.values.phone}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Note"
                            fullWidth
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            defaultValue="Message"
                            name={"note"}
                            value={formik.values.note}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.note && Boolean(formik.errors.note)}
                            helperText={formik.touched.note && formik.errors.note}
                        />
                    </Grid>
            </Grid>  
            <Button type={'submit'} variant={'contained'}> Add </Button> 
            </form>
               </Box>
    )

 }
 export default Contact 
import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { useEffect } from "react";
import { useState } from "react";
import axios from 'axios';
import { get } from '../../api/rest';

export default function Grill() {
   const [data,setData] = useState([])


   useEffect(()=>{
    //alert('From grills useEffect')
    // axios.get('http://15.207.106.5:9090/api/product/all/grill').then((response)=>{
    //   setData(response.data)
    //   console.log("from grills component",response)
    //   return(data)
    // });
    get('api/product/all/grill').then(res=>{
      console.log('grills res from ec2', res);
      setData(res);
   });

   },[])


  return (
   <Box>
      <Grid container spacing={4}>
      {data.map((item,i)=>(
         <Grid item xs={12} md={4} sm={6} key={i} alignItems="center"> 
         <Box>
          <Card sx={{ maxWidth: 345 }}>
          <CardHeader
            title={item.name}
            subheader={item.description}
          />
          <CardMedia
            image={'https://res.cloudinary.com/oleifera-labs/image/upload/v1617773256/chicken-grill_v98g4x.jpg'}
            component="img"
            height="194"
            alt="Paella dish"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              This impressive paella is a perfect party dish and a fun meal to cook
              together with your guests. Add 1 cup of frozen peas along with the mussels,
              if you like.
            </Typography>
          </CardContent>
        </Card>
        </Box>
        </Grid>
      ))}
      </Grid>
    
    </Box>
  );
}


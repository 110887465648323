/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our story
            </Typography>
            <Typography component={'p'}>
            Our restaurant started with a passion for food and a desire to create a welcoming space where people could come together and enjoy delicious meals in a warm and 
            inviting atmosphere.
              <br />
              Our founders, Raj and Thiru, have always had a deep love for food. They spent years exploring 
              different cuisines and experimenting with new recipes in their home kitchen. As they honed their skills and developed their own unique style, they dreamed of opening a restaurant where they could share their 
              love of food with the world.
              <br />
              <br />
              Our chefs are passionate about using fresh, locally sourced ingredients to create dishes that are both 
              flavorful and nutritious. We believe that great food doesn't have to come at the expense of your health, which is why we prioritize quality ingredients and thoughtful preparation techniques
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={500} width={1}>
            <Box
              component={'img'}
              src={
                '/images/rgba.jpeg'
              }
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;

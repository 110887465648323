import { useState } from "react";
import axios from 'axios';
import { useEffect } from "react";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { get } from "../../api/rest";

const biryanis = [
  {
    image: 'https://res.cloudinary.com/oleifera-labs/image/upload/v1617773256/chicken-grill_v98g4x.jpg',
    description: 'Smoky Grilled with Green chutney',
    title: 'Grilled Chicker',
    quantity: '300grams',
    price: 'Rs 99/-'
  },
  {
    image: 'https://res.cloudinary.com/oleifera-labs/image/upload/v1617773256/chicken-grill_v98g4x.jpg',
    description: 'Smoky Grilled with Green chutney',
    title: 'Butter Chicker',
    quantity: '300grams',
    price: 'Rs 99/-'
  }
];

const Biryani = () =>{
  let localUrl = 'https://res.cloudinary.com/oleifera-labs/image/upload/v1617773256/chicken-grill_v98g4x.jpg'
    const [data,setData] = useState([])
    const theme = useTheme();

    useEffect(() => {
        // axios.get('http://15.207.106.5:9090/api/product/all/biryani').then((response) => {
        //   setData(response.data);
        // });
       get('api/product/all/biryani').then(res=>{
          console.log('biryanis res from ec2', res);
          setData(res);
       });
        
        
      }, [])
      console.log("from biryani get api",data);
    return(
      <Box>
      <Grid container spacing={4}>
        {data.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={'a'}
              href={''}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.url ? item.url: localUrl } 
                  title={item.title}
                  quantity={item.quantity}
                  price={item.price}
                  sx={{
                    height: { xs: 340, md: 400 },
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
                <Box component={CardContent}>
                  <Typography variant={'h6'} fontWeight={700} gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant={'body2'} color="text.secondary">
                    {item.description}
                  </Typography>
                  <Grid container justify={"space-between"}>
                  <Typography variant={'h6'} fontWeight={400} gutterBottom>
                    {item.quantity}
                  </Typography>
                  <Typography variant={'h6'} fontWeight={400} gutterBottom>
                    {item.price}
                  </Typography>
                  </Grid>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={'flex-start'}>
                  <Button
                    size="large"
                    endIcon={
                      <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    }
                  >
                    Add to Cart
                  </Button>
                  <ShoppingCartIcon/>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
    )

}
export default Biryani
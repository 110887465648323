import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import axios from "axios";
import Button from '@mui/material/Button';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ProductList(props) {

    const [data,setData] = useState([])
    const [delet, setDelete] = useState(false)
    const [editFlag, setEditFlag] = useState(false)


    // useEffect(()=>{
    //     axios.get('http://15.207.106.5:9090/api/contact/list').then((response)=>{
    //              console.log('Contact list Api',response.data)
    //              setData(response.data)
    //     }).catch((error)=>{
    //           console.log('internal error',error)
    //     })
    // },[])
     useEffect(()=>{
        axios.get('http://15.207.106.5:9090/api/product/all').then((response)=>{
            console.log('from allproduct api',response.data)
            setData(response.data)
        }).catch((error)=>{
            console.log(error)

        })

     },[])
      
     let token = localStorage.getItem("token");

     
      let headers = {
        'Authorization': 'Bearer '+  token,
      }
    
    const deleteItem =(id)=>{
         alert('dltItem'+id)
        axios.delete('http://15.207.106.5:9090/api/product/delete/'+ id,{headers})
        .then((response)=>{
          console.log(response.data)
          setDelete(true)
         })
    }
     const editItem = (item)=>{
      console.log('item object', props)
      // alert('bitoon clicked'+ name)
      //setEditFlag(true)
         props.fun1(item)
     }
  return (
    <>
     <h1>{props.description}</h1>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>ItemType</StyledTableCell>
            <StyledTableCell align="right">Name</StyledTableCell>
            <StyledTableCell align="right">Description</StyledTableCell>
            <StyledTableCell align="right">Price</StyledTableCell>
            <StyledTableCell align="right">Edit</StyledTableCell>
            <StyledTableCell align="right" >Delete</StyledTableCell>
            {/* <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <StyledTableRow key={item.name}>
              <StyledTableCell component="th" scope="row">
                {item.itemType}
              </StyledTableCell>
              <StyledTableCell align="right">{item.name}</StyledTableCell>
              <StyledTableCell align="right">{item.description}</StyledTableCell>
              <StyledTableCell align="right">{item.price}</StyledTableCell>
              {/* <StyledTableCell align="right">{row.protein}</StyledTableCell> */}
              <StyledTableCell align="right"><Button variant="contained" onClick={()=>editItem(item)}>Edit</Button></StyledTableCell>
              <StyledTableCell align="right"><Button variant="contained" color='secondary' onClick={()=>deleteItem(item.id)}>Delete</Button></StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}
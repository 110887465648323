import React from 'react';

import {
  PortfolioMasonry as PortfolioMasonryView,
  SigninSimple as SigninSimpleView,
  SignupSimple as SignupSimpleView,
 
} from '../views';
import Franchise from './Franchise/Franchise';
import Features from './Franchise/Features';
import Biryani from './Biryani/Biryani';
import Grill from './Grill/Grill';
import TodaySpl from './TodaySpl/TodaySpl';
import Catering from './Catering/catering';
import Admin from './Admin/Admin';
import CateringAdmin from './Admin/Catering';
import FranchiseAdmin from './Admin/Franchise';
import Drawer from './Admin/Drawer';
import Contact from './Contact/contact';
import Dailog from './Dailog/Dailog';
//import SigninSimple from '../views/SigninSimple';
import Message from './Admin/Message';
const routes = [
  {
    path: '/',
    renderer: (params = {}) => <PortfolioMasonryView {...params} />,
  },
  {
    path:'/signin',
    renderer: (params = {}) => <SigninSimpleView {...params} />,
  },
  {
    path:'/signup',
    renderer: (params = {}) => <SignupSimpleView {...params} />,
  },
  {
    path:'/franchise',
    renderer: (params = {}) => <Franchise {...params} />,
  },
  {
    path:'/features',
    renderer: (params = {}) => <Features {...params} />,
  },
  {
    path:'/biryani',
    renderer: (params = {}) => <Biryani {...params} />,
  },
  {
    path:'/grill',
    renderer:(params = {})=><Grill {...params}/>
  },
  {
    path:'/todayspl',
    renderer:(params = {})=><TodaySpl {...params}/>
  },
  {
    path:'/catering',
    renderer: (params = {}) => <Catering {...params}/>,
  },
  {
    path:'/admin/product',
    renderer: (params = {})=><Admin {...params}/>
  },
  {
    path:"/admin/catering",
    renderer: (params = {})=><CateringAdmin {...params}/>
  },
  {
    path:'/admin/franchise',
    renderer: (params = {})=><FranchiseAdmin {...params}/>
  },
  {
    path:'/admin/drawer',
    renderer: (params = {})=><Drawer {...params}/>
  },
  {
    path:'/contact',
    renderer:(params = {})=><Contact {...params}/>
  },
  {
    path:'/massage',
    renderer:(params = {})=><Message {...params}/>
  }

];
export default routes;
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Grid, Typography } from '@mui/material';
import { Form, useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';



const validationSchema = yup.object({
  email: yup
    .string()
    .email()
    .required()
    .trim()
})


export default function MaxWidthDialog() {
  const [open, setOpen] = React.useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [statusFlag, setStatusFlag] = React.useState(false);
  const [errorFlag, setErrorFlag] = React.useState(false)

  const initialValues = {
    email: ''
  }
  const onSubmit = (values) => {
    console.log('from onsubmit', values.email)
    axios.post('http://15.207.106.5:9090/api/aws/sns/addSubscription/' + values.email).then((response) => {
      console.log('from dailig api', response);
      if (response.status == 200) {
        // alert('Received your mail please confirm throug your mail')
        // handleClose();
        setStatusFlag(true)
      }
    }).catch(error => {
      console.log('from error api1 ', error);
      setErrorFlag(true)
    });
  }
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit
  })
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };
  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>

        <Dialog
          // fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Subscribe Now</DialogTitle>
          <DialogContent>
            <Grid>
              <DialogContentText>
                Enter your email
              </DialogContentText>
            </Grid>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email *"
                  variant="outlined"
                  name={'email'}
                  fullWidth
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid>
                <DialogActions>
                  <Button variant={'contained'} type={'submit'}>Send</Button>
                </DialogActions>
                {statusFlag && <p>Received your mail please confirm throug your mail</p>}
                {errorFlag && <p>Internal issue</p>}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>

        </Dialog>
      </form>
    </React.Fragment>
  );
}
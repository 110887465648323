
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Admin from './Admin';
import Franchise from './Franchise';
import Catering from './Catering';
import Message from './Message';
import ContactList from './ContactList';
import ProductList from './ProductList';
import EditList from './editList';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [addFlag, setAddFlag] = React.useState(false)
  const [franchiseFlag, setFranchiseFlag] = React.useState(false)
  const [catFlag, setCatFlag] = React.useState(false)
  const [mesFlag, setMesFlag] = React.useState(false)
  const [conFlag, setConFlag] = React.useState(false)
  const [proFlag, setProFlag] = React.useState(false)
  const [editFlag, setEditFlag] = React.useState(false)
  const [rowData, setRowData] = React.useState({})


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const btnClick =(text) =>{
    
    // alert("btn clicked for "+ text)
    if(text === 'addProduct'){
      setAddFlag(true);
      setFranchiseFlag(false);
      setCatFlag(false);
      setMesFlag(false);
      setConFlag(false);
      setProFlag(false);
      setEditFlag(false)




    }else if(text === 'addFranchise'){
       setFranchiseFlag(true);
       setAddFlag(false);
       setCatFlag(false);
       setMesFlag(false);
       setConFlag(false);
       setProFlag(false);
       setEditFlag(false)





    }else if(text === 'addCatering'){
      setCatFlag(true);
      setAddFlag(false);
      setFranchiseFlag(false);
      setMesFlag(false);
      setConFlag(false);
      setProFlag(false);
      setEditFlag(false)




    }else if (text === 'Offers'){
      setMesFlag(true);
      setCatFlag(false);
      setAddFlag(false);
      setFranchiseFlag(false);
      setConFlag(false);
      setProFlag(false);
      setEditFlag(false)



    }else if(text === 'ContactList'){
      setConFlag(true);
      setMesFlag(false);
      setCatFlag(false);
      setAddFlag(false);
      setFranchiseFlag(false);
      setProFlag(false);
      setEditFlag(false)


    }else if(text === 'ProductList'){ 
        setProFlag(true);
        setConFlag(false);
        setMesFlag(false);
        setCatFlag(false);
        setAddFlag(false);
        setFranchiseFlag(false);
        setEditFlag(false)

        
    }else{ 
      setEditFlag(true)
      setProFlag(false);
      setConFlag(false);
      setMesFlag(false);
      setCatFlag(false);
      setAddFlag(false);
      setFranchiseFlag(false);
  }

  }

  const parentFunc =(data)=>{
         console.log('ChilData1',data);
         setRowData(data)
         setEditFlag(true);
         setProFlag(false);
         setConFlag(false);
         setMesFlag(false);
         setCatFlag(false);
         setAddFlag(false);
         setFranchiseFlag(false);

  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Admin Login
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['addProduct', 'addFranchise', 'addCatering', 'Offers', 'ContactList','ProductList'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={()=>btnClick(text)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {addFlag && <Admin/>}
        {franchiseFlag && <Franchise/>}
        {catFlag && <Catering/>}
        {mesFlag && <Message/>}
        {conFlag && <ContactList/>}
        {proFlag && <ProductList description = 'list of available products' fun1={parentFunc}/>}
        {editFlag && <EditList rowData = {rowData}/>}
      </Box>
    </Box>
  );
}
import React,{useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { useDispatch,useSelector } from "react-redux";

import {increment} from '../../redux-new/store'

const mock = [
  {
    image: 'https://res.cloudinary.com/oleifera-labs/image/upload/v1617773256/chicken-grill_v98g4x.jpg',
    description: 'Smoky Grilled with Green chutney',
    title: 'Grilled Chicker',
    quantity: '300grams',
    price: 89
  },
  {
    image: 'https://res.cloudinary.com/oleifera-labs/image/upload/v1617773256/chicken-grill_v98g4x.jpg',
    description: 'Smoky Grilled with Green chutney',
    title: 'Grilled Chicker',
    quantity: '300grams',
    price: 99
  },
  {
    image: 'https://res.cloudinary.com/oleifera-labs/image/upload/v1617773256/chicken-grill_v98g4x.jpg',
    description: 'Smoky Grilled with Green chutney',
    title: 'Grilled Chicker',
    quantity: '300grams',
    price: 89
  },
  {
    image: 'https://res.cloudinary.com/oleifera-labs/image/upload/v1617773256/chicken-grill_v98g4x.jpg',
    description: 'Smoky Grilled with Green chutney',
    title: 'Grilled Chicker',
    quantity: '300grams',
    price: 88
  },
  {
    image: 'https://res.cloudinary.com/oleifera-labs/image/upload/v1617773256/chicken-grill_v98g4x.jpg',
    description: 'Smoky Grilled with Green chutney',
    title: 'Grilled Chicker',
    quantity: '300grams',
    price: 100
  },
];

const GridView = () => {

  useEffect(()=>{

  },[])

  const dispatch = useDispatch();

  const addToCart = (e,item) => {
    e.preventDefault();
    //console.log('item details',item);
    console.log('add to cart clicked !!!');
    dispatch(increment(item));
  }
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={'a'}
              href={''}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.image}
                  title={item.title}
                  quantity={item.quantity}
                  price={item.price}
                  sx={{
                    height: { xs: 340, md: 400 },
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
                <Box component={CardContent}>
                  <Typography variant={'h6'} fontWeight={700} gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant={'body2'} color="text.secondary">
                    {item.description}
                  </Typography>
                  <Grid container justify={"space-between"}>
                    <Grid item>
                      <Typography variant={'h6'} fontWeight={400} gutterBottom>
                        {item.quantity}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={'h6'} fontWeight={400} gutterBottom>
                        {item.price}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} onClick={(e)=>addToCart(e,item)} justifyContent={'flex-start'}>
                  <Button
                    size="large"
                    endIcon={
                      <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    }
                    
                  >
                    Add to Cart
                  </Button>
                  <ShoppingCartIcon/>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GridView;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// import RadioBtn from './radioBtn'
import axios from 'axios'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import { post } from '../../api/rest';



const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required('username is required.'),
    description: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have at minimum length of 8'),
    price: yup
    .string()
    .required('please enter price'),    

});

const Admin = () => {
  const initialValues = {
    name: '',
    description: '',
    price:'',
    itemType:'',
    file:null
  };
 let ip = '15.207.106.5'
 let token = localStorage.getItem("token");


 

  const onSubmit = (values) => {
    console.log(values)
    console.log("Tokeeeen",token)
    let options ={
      headers: {
        'Authorization': 'Bearer '+  token,
        'content-type': 'multipart/form-data'
      }
    }

     console.log("from onsubmit",JSON.stringify(values))
    // axios.post('http://15.207.106.5:9090/api/product/add',
    // values,
    // options
    // )
    //  .then(response => console.log('response from api',response.data)); 
    
    post("api/product/add", values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const fileValue = (event)=>{
    console.log("from fileValue",)
    formik.setFieldValue("file", event.currentTarget.files[0])

  }

  return (
    <>
    {token ?  
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Product
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Add new dish
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter dish name
            </Typography>
            <TextField
              label="Name *"
              variant="outlined"
              name={'name'}
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'column' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              marginBottom={2}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  Enter description
                </Typography>
              </Box>
            </Box>
            <TextField
              label="Description *"
              variant="outlined"
              name={'description'}
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter item price
            </Typography>
            <TextField
              label="Price"
              variant="outlined"
              name={'price'}
              fullWidth
              value={formik.values.price}
              onChange={formik.handleChange}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
            />
          </Grid>
          <Grid>
          <FormControl sx={{ m: 2,mt:5,ml:4, minWidth: 400 }} size="large">
      <InputLabel id="demo-select-small">itemType</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        name='itemType'
        value={formik.values.itemType}
        label="itemType"
        onChange={formik.handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={'grill'}>Grill</MenuItem>
        <MenuItem value={'biryani'}>Biryani</MenuItem>
        <MenuItem value={'chinies'}>Chinies</MenuItem>
      </Select>
    </FormControl>
          </Grid>

          <Grid>
          <Input type={'file'}  onChange={fileValue} variant={'contained'}>Insert file</Input>
          </Grid>

          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              // justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Button size={'large'} variant={'contained'} type={'submit'}>
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box> :  <h6>Please login first</h6>

      }
    </>
  );
};


export default Admin;

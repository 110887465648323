import axios from "axios";
let url = "http://3.144.112.144:9090/";
//let url = "http://localhost:9090/";
export const post = (uri,payload,options) =>{
    console.log("from rest post api",payload)

    return axios.post(url+uri, payload, options)
        .then(response => console.log('response from api',response)); 
}
export const get = (uri) => {
    //let res = axios.get(url+uri);
    //return res.data;
    return fetch(url+uri)
    .then(data => data.json())
}
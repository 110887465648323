import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            component="a"
            href="/rest"
            title="RGBA"
            sx={{textDecoration:'none'}}
            width={80}
          >
            RGBA         
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
      <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
         ShivaTheater, Collectrate, Rukhmapur
        </Typography>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; RGBA, All rights reserved
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
          When you visit or interact with our sites, the price might differ
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;

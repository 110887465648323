//////// types ///////
const INCREMENT = "increment";
const DECREMENT = "decrement";
//////// types ///////

////// actions ////////////
export const increment = (item) => ({
    type: INCREMENT,
    item:item
  });
export const decrease = () => ({
    type: 'decreaseCount'
  });
////// actions ////////////

const initialState = {
    count: 0,
    items:[],
    totalPrice:0
};

export default (state = initialState, action) => {
    console.log("action",action)
    switch (action.type) {
      case INCREMENT:
        
      //let c = { ...state, count:state.count+ 1}; // updating single property

      // updating multiple properties
        // let itemsArr = state.items;
        // let cpitemsArr= [...itemsArr,action.item]
        // let c = { ...state, count:state.count+ 1};
        // let d = {...state, items:cpitemsArr};
        // return d;

        let itemsArr = state.items;
        let cpitemsArr= [...itemsArr,action.item]
        let c = { ...state, 
          count:state.count+ 1,
          items:[...itemsArr,action.item],
          totalPrice: state.totalPrice+action.item.price
        };
        return c
      case 'decreaseCount':
        console.log("inside increaseCount case");
        //let c = { ...state};
        return state;
      default:
        return state;
    }
};
  
import { combineReducers, createStore } from "redux";
import zappyReducer from "./store";

// import wishReducer from "./wishStore";
// import cartReducer from "./cartStore";
// import userReducer from "./userStore";

const zreducer = combineReducers({
  zappy: zappyReducer,
  // wish: wishReducer,
  // cart: cartReducer,
  // user: userReducer
});

const store = createStore(zreducer);

export default store;
import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { get } from '../../api/rest';


const caterings = {
    "strenth": 100,
    "events": [
      "Birthdays",
      "HouseWarming"
    ],
    "items": [
      {
        "type": "veg",
        "details": [
          {
            "staters": [
              "paneer",
              "Gobi"
            ],
            "curries": [
              "Tomoto",
              "pappu"
            ]
          }
        ]
      },
      {
        "type":"non-veg",
        "details":[
            {
              "staters":[
                "chilli-chick",
                "drumstick"
              ],
              "curries":[
                "mutton",
                "fish"
              ]
            }
        ]
      }
    ]
  }
const Catering = () => {

  const [data,setData] = useState([''])

  useEffect(()=>{
    // axios.get('http://15.207.106.5:9090/api/catering/info').then((response)=>{
    //   console.log('from catering get call',response)
    //   setData(response.data)
    //   return response
    // });
    get('api/catering/info').then(res=>{
      console.log('catering res from ec2', res);
      setData(res);
   });

  },[])

    return (
        <>
            <h1>{data.items}</h1>
            <h1>{data.price}</h1>
            <h1>{data.strenth}</h1>

        </>
    )

}
export default Catering
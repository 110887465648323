import  React,{useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { SigninSimple } from '../views';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Cart from './Cart';

export default function ButtonAppBar() {

  const[isOpen, setIsOpen] = React.useState(false);
  const history = useHistory();
  //const count = useSelector((state) => state.itemreducer.count);
  const count = useSelector((state) => state.zappy.count);
  const items = useSelector((state) => state.zappy.items);
  const totalPrice = useSelector((state) => state.zappy.totalPrice);
  console.log("items from appbar", items);
  console.log("totalPrice", totalPrice);
  
  function login(){
   history.push("/signin")
  }
  const franch = ()=>{
    history.push('/features')  
  }
  const bir = ()=>{
    history.push('/biryani')
  }
  const gri = ()=>{
      history.push('/grill')
  }
  const tod =()=>{
    history.push('/todayspl')
  }
  const cat =()=>{
    history.push('/catering')
  }
  const admin =()=>{
    history.push('/admin/drawer')
  }
  const contact = ()=>{
    history.push('/contact')
  }
  // {
  //   history.push('/features')
  // }
  const openCart = () => {
    console.log('open cart clicked !!!');
    setIsOpen(true);
  }

  useEffect(()=>{

  },[isOpen])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            RGBA-R
          </Typography>
          <Button color="inherit" onClick={bir}>Biryani's</Button>
          <Button color="inherit" onClick={gri}>Grill</Button>
          <Button color="inherit" onClick={cat}>Catering</Button>
          <Button color="inherit" onClick={tod}>Today's Special</Button>
          <Button color="inherit" onClick={franch}>Franchise</Button>
          <Button color="inherit" onClick={login}>Login</Button>
          <Button color="inherit" onClick={admin}>Admin</Button>
          <Button color="inherit" onClick={contact}>Contact US</Button>
          <Box onClick={openCart}>
              <ShoppingCartIcon/> {count}
          </Box>
        </Toolbar>
      </AppBar>
      <Cart isOpen={isOpen}/>
    </Box>
  );
}

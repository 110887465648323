import { Button, TextField, Typography } from "@mui/material"
import { Box, Grid } from "@mui/material"
import axios from "axios";
import { Formik, useFormik} from "formik"
import * as yup from 'yup';

const Franchise = () => {
    const validationSchema = yup.object({
        price:yup.number().required(),
        furniture:yup.string().required(),
        food:yup.string().required()

    })
    const initialValues ={
        price:'',
        furniture:'',
        food:''
    }
    const onSubmit = (values)=>{
        console.log("from onSubmit",values)
        axios.post('http://15.207.106.5:9090/api/franchise/info',values).then((response)=>{
            console.log("from AdmitCatering Post call",response)

        })

    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })
    return (
        <>
        <h1>Franchise Details</h1>
            <Box>
                <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={4} direction={"row"}>
                    <Grid item xs={6} sm={6}>
                        <Typography>
                            Price
                        </Typography>
                        <TextField
                            label="Price *"
                            variant="outlined"
                            name={'price'}
                            fullWidth
                            value={formik.values.price}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.price && Boolean(formik.errors.price)}
                            helperText={formik.touched.price && formik.errors.price}

                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography>
                            Furniture
                        </Typography>
                        <TextField
                            label='Furniture'
                            variant="outlined"
                            name={'furniture'}
                            fullWidth
                            value={formik.values.furniture}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.furniture && Boolean(formik.errors.furniture)}
                            helperText={formik.touched.furniture && formik.errors.furniture}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography>
                            Food
                        </Typography>
                        <TextField
                            label='Food'
                            variant="outlined"
                            name={'food'}
                            fullWidth
                            value={formik.values.food}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.food && Boolean(formik.errors.food)}
                            helperText={formik.touched.food && formik.errors.food}
                        />
                    </Grid>
                </Grid>
                <Grid mt={4}>
                <Button variant={'outlined'} type={'submit'} > Add</Button>
                </Grid>
                </form>
            </Box>
        </>
    )
}
export default Franchise